import React from "react"
import LocalizedLink from "../localizedLink";
import Styles from "./SoftwareDownloadButton.module.scss"
import useTranslations from "../useTranslations";

const SoftwareDownloadButton = (props) => {
    const {agreeAndDownload, disagreeAndNotDownload} = useTranslations()
    const downloadUrl = (yourKey, tmpKey) => {
        fetch(`https://23jvp9yccg.execute-api.ap-northeast-1.amazonaws.com/test/s3?your-key=${yourKey}&tmp-key=${tmpKey}`)
            .then((data) => {
                    window.location.href = data.headers.get('location')
                }
            )
    }
        return (
            <div className={`${Styles.catalogDownloadButton} flex column`}>
                <div className={`${Styles.catalogDownloadAgree} flex row-to-column`}>
                    {props.yourKey != null &&
                    <button className="catalog-download-agree-64bit" onClick={() =>{downloadUrl(props.yourKey, props.tmpKey)}}>
                        {agreeAndDownload}
                    </button>
                    }
                    {props.yourKey64bit != null &&
                    <button className="catalog-download-agree-64bit" onClick={() =>{downloadUrl(props.yourKey64bit, props.tmpKey64bit)}}>
                        {agreeAndDownload}(64bit)
                    </button>
                    }
                    {props.yourKey32bit != null &&
                    <button className="catalog-download-agree-32bit" onClick={() => {downloadUrl(props.yourKey32bit, props.tmpKey32bit)}}>
                        {agreeAndDownload}(32bit)
                    </button>
                    }
                </div>
                <div className={Styles.catalogDownloadDisagree}>
                    <LocalizedLink to={`/support/software-download/`}>{disagreeAndNotDownload}</LocalizedLink>
                </div>
            </div>
        )
}

export default SoftwareDownloadButton;